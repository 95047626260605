import photosVKApi from "../../../api/vk_api";

export const SET_YEAR = 'SET_YEAR';
export const GET_ALL_PHOTOS = 'GET_ALL_PHOTOS';
export const GET_PHOTOS_REQUEST = 'GET_PHOTOS_REQUEST';
export const GET_PHOTOS_SUCCESS = 'GET_PHOTOS_SUCCESS';
export const GET_PHOTOS_FAIL = 'GET_PHOTOS_FAIL';

let photosArr = [];
let cached = false;
let localStorageYear = null;

export function setYears(){
    return dispatch => {
        localStorageYear = parseInt(localStorage.getItem('year'));
        if (localStorageYear === null) {
            localStorageYear = 2020;
        }
        dispatch({
            type: SET_YEAR,
            payload: localStorageYear,
        });
    }
}

function makeYearPhotos(photo, year) {
    let createdYear,
        yearPhoto = [];

    photo.forEach(item => {
        createdYear = new Date(item.date * 1000).getFullYear();
        if (createdYear === year) {
            yearPhoto.push(item)
        }
    });
    return yearPhoto
}

const getAllPhotoSuccess = () => {
    return photosVKApi.getPhotos(200)
        .then(photos => {
            const { response: { items } = {} } = photos;

            photosArr = [
                ...(Array.isArray(items) ? items : [])
            ];

            cached = true;
        })
};

export const getAllPhoto = () => (dispatch) => {
    getAllPhotoSuccess()
        .then(() => {
            let photo = makeYearPhotos(photosArr, localStorageYear);
            dispatch({
                type: GET_PHOTOS_SUCCESS,
                payload: photo,
            })
        })
        .catch(error => {
            console.log(error);
            dispatch({
                type: GET_PHOTOS_FAIL,
            })
        });
};

export function getPhotos(year) {
    return dispatch => {
        dispatch({
            type: GET_PHOTOS_REQUEST,
            payload: year,
        });
        if (cached === true) {
            let photo = makeYearPhotos(photosArr, year);
            dispatch({
                type: GET_PHOTOS_SUCCESS,
                payload: photo,
            })
        } else {
            getAllPhoto(0, 200, year, dispatch);
        }
    }
}
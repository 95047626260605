import React from 'react';
import FadeTransition from "../../../../assets/transition/transition_home";
import {Container, Col, Row} from 'reactstrap';
import '../../../../assets/blocks/main.scss';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import Photo from "../../../moduls/photo/photo";
import ButtonReturn from "../../../moduls/button/btn_return/bnt_return";

const Portfolio = (props) => {

    let state = props.portfolioPage;
    let getPhotosAction = props.getPhotosAction;

    return (
        <FadeTransition>
            <Header/>
            <p><a name="section"></a></p>
            <ButtonReturn />
            <div className='portfolio_main'>
                <Container>
                    <Row>
                        <Col xs="12" className="home_we_worked">
                            <p>Мы работаем</p>
                        </Col>
                    </Row>
                </Container>
                <div className='portfolio'>
                    <Photo
                        year={state.year}
                        isProcessing={state.isProcessing}
                        photo={state.photo}
                        getPhoto={getPhotosAction}
                        />
                </div>
            </div>
            <Footer />
        </FadeTransition>
    );
};

export default Portfolio;
import React from 'react';
import {
    Container,
    Row,
    Card,
    CardTitle,
    CardText,
    CardFooter,
    CardBody,
    CardHeader
} from 'reactstrap';
import './work_content.scss';
import Header from '../header/header';
import Footer from '../footer/footer';
import ButtonOrder from "../../moduls/button/btn_order";
import WorkMenu from "../../moduls/work_menu/work_menu";
import ButtonReturn from "../../moduls/button/btn_return/bnt_return";
import FadeTransitionWorkLayout from "../../../assets/transition/transition_works_layouts";

export class TechniksLayout extends React.Component {

    renderText = () => {
        const text= [
            {
                id: 1,
                works: 'Манипулятор',
                about_1: 'Грузоподъемность: 5 тонн',
                about_2: 'Грузоподъемность стрелы: 3 тонны',
                about_3: 'Вылет стрелы: 10 метров',
                about_4: 'Стоимость 1 км за городом: 30 р/км;',
                time: '08:00 - 20:00',
                order: '2',
                price: 'от 1200'
            },
            {id: 2,
                works: 'Манипулятор',
                about_1: 'Грузоподъемность: 5 тонн',
                about_2: 'Грузоподъемность стрелы: 3 тонны',
                about_3: 'Вылет стрелы: 10 метров',
                about_4: 'Стоимость 1 км за городом: 30 р/км;',
                time: '20:00 - 08:00',
                order: '2',
                price: 'от 1400'
            },
            {id: 4,
                works: 'Эвакуатор',
                time: 'Круглосуточно',
                order: '2',
                price: 'от 1500'
            },
            // {id: 5,
            //     works: 'до 3000 кг',
            //     time: '20:00 - 08:00',
            //     order: '2',
            //     price_km: '20',
            //     price: '900'
            // },
            // {id: 6,
            //     works: 'до 5000 кг',
            //     time: '08:00 - 20:00',
            //     order: '2',
            //     price_km: '25',
            //     price: '1200'
            // },
            // {id: 7,
            //     works: 'до 5000 кг',
            //     time: '20:00 - 08:00',
            //     order: '2',
            //     price_km: '25',
            //     price: '1400'
            // }
        ];

        return text.map((item, i) => {
            return (
                <Card body className='work_content__card' key={i}>
                    <FadeTransitionWorkLayout>
                        <CardHeader className='work_content__card-info-header'>Тип спецтехники:</CardHeader>
                        <CardHeader className='work_content__card-info-header-title'>{item.works}</CardHeader>
                        <CardBody>
                            <CardTitle>Время работы: {item.time}</CardTitle>
                            <CardText>{item.about_1}</CardText>
                            <CardText>{item.about_2}</CardText>
                            <CardText>{item.about_3}</CardText>
                            <CardText>{item.about_4}</CardText>
                            <CardText>Минимальный заказ (часы): {item.order}</CardText>
                        </CardBody>
                        <CardFooter>Цена:</CardFooter>
                        <CardFooter className='work_content__card-info-footer-price'>{item.price} &#8381;/час</CardFooter>
                    </FadeTransitionWorkLayout>
                </Card>
            )
        })
    };

    render() {
        return(
            <div>
                <Header/>
                <p><a name="section"></a></p>
                <ButtonReturn />
                <WorkMenu {...this.props}/>
                <Container  className='work_content'>
                    <Row>
                        <Card className='work_content__card'>
                            <CardHeader className='work_content__card-info-header-title'>
                                <FadeTransitionWorkLayout>
                                    <span>Аренда спецтехники:</span>
                                </FadeTransitionWorkLayout>
                            </CardHeader>
                        </Card>
                    </Row>
                    <Row>

                        { this.renderText() }

                        <Card body className='work_content__card'>
                            <CardHeader className='work_content__card-info-header-title' >Междугородние перевозки всеми типами автомобилей</CardHeader>
                            <CardBody>
                                <CardTitle>Время работы: 00:00 - 24:00 </CardTitle>
                                <CardText>Минимальный заказ (часы): 2 </CardText>
                            </CardBody>
                            <CardFooter>Цена:</CardFooter>
                            <CardFooter className='work_content__card-info-footer-price'>
                                Стоимость расчитывается по километражу туда/обратно
                            </CardFooter>
                        </Card>
                    </Row>
                </Container>
                <ButtonOrder/>
                <Footer/>
            </div>
        )
    }
}

export default TechniksLayout;
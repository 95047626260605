import React from 'react';
import {
    Col, Container,
    Row
} from "reactstrap";

import './footer.scss';

const Footer = () => {

    return (
        <div className="footer">
            <Row  style={{
                paddingTop: '1rem',
                fontSize: '1vmax',
                width: '100%'
            }}>
                <Col xs={{ size: '6' }} style={{
                    textAlign: 'left',
                }}>
                    <img className='footer_left-icon'
                         src="../img/footer_left.png" alt="ivan_yarigin" />
                </Col>

                <Col xs={{ size: '6' }} style={{
                    textAlign: 'right',
                }}>
                    <img className='footer_right-icon'
                         src="../img/footer_right.png" alt="big_ben"/>
                </Col>
            </Row>
            <hr />
            <Container className='footer_text'>
                <Row>
                    <Col>
                        <img className='footer_logo'
                             src="../img/logo_full.png" alt="big_ben"/>
                        <p className='footer_altlogo'> Трезвые Грузчики</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a href='tel:+79535858975' style={{textDecorationLine: 'none'}}>&#128383; +7 (391) 285-89-75</a>
                    </Col>
                </Row>
            </Container>
            <hr />
            <Container className='footer_text-production'>
                <Row>
                    <Col style={{textAlign: 'right'}}>
                        <p> идея и разработка:</p>
                        <p>@2019 Дмитрий Латышев</p>
                    </Col>
                    <Col style={{textAlign: 'left'}}>
                        <p> дизайн:</p>
                        <p>@2019 Мария Шагалова</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Footer;
import React from 'react';
import {CSSTransitionGroup} from "react-transition-group";
import './transition.scss';

const FadeTransitionWorkLayout = (props) => (
    <CSSTransitionGroup
        {...props}
        transitionName="work"
        transitionAppear={true}
        transitionAppearTimeout={1000}
        transitionEnter={false}
        transitionLeave={false}
    />
);
export default FadeTransitionWorkLayout;
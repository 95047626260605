import {
    SET_YEAR,
    GET_PHOTOS_REQUEST,
    GET_PHOTOS_SUCCESS,
    GET_PHOTOS_FAIL,
    GET_ALL_PHOTOS
} from './portf_action.js'

const initialState = {
    year: null,
    photo: [],
    isProcessing: false,
    error: '',
};

export function portfolioReducer(state = initialState, action) {
    switch (action.type) {

        case SET_YEAR:
            return { ...state,
                year: action.payload,
                isProcessing: false,
                error: ''
            };
        case GET_ALL_PHOTOS:
            return {
                ...state,
                photo: action.payload,
                isProcessing: false,
                error: ''
            };

        case GET_PHOTOS_REQUEST:
            return { ...state,
                year: action.payload,
                isProcessing: true,
                error: ''
            };

        case GET_PHOTOS_SUCCESS:
            return { ...state,
                photo: action.payload,
                isProcessing: false,
                error: ''
            };

        case GET_PHOTOS_FAIL:
            return { ...state,
                isProcessing: false,
                error: 'error',
            };

        default:
            return state
    }
}


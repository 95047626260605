import React from 'react';
// import PropTypes from 'prop-types';
import {Button} from "reactstrap";

import './slider.scss';

export class SliderFilms extends React.Component {

    onBtnClickSliders = year => {
        localStorage.setItem('year', year);
    };

    renderFilms = () => {
        const films = [
            {id: 1, border: '../img/border_slider/border_1.png',
                photo: '../img/border_slider/2020.jpg', year: 2020},
            {id: 2, border: '../img/border_slider/border_4.png',
                photo: '../img/border_slider/2019.jpg', year: 2019},
            {id: 3, border: '../img/border_slider/border_3.png',
                photo: '../img/border_slider/2018.jpg', year: 2018},
            {id: 4, border: '../img/border_slider/border_4.png',
                photo: '../img/border_slider/2017.jpg', year: 2017},
            {id: 5, border: '../img/border_slider/border_5.png',
                photo: '../img/border_slider/2016.jpg'}
        ];

        return films.map((item, i) => {
            return (
                <div className='slid__slider_render' key={i}>
                    <a href='/portfolio#section'
                       onClick={() => { this.onBtnClickSliders(item.year)}}>
                        <img className='slid__slider_render-photo'
                             src={item.photo} alt=''/>
                        <img className='slid__slider_render-border'
                             src={item.border} alt=''/>
                    </a>
                    <Button className='slid__slider_btn'
                            key={i}
                            onClick={() => { this.onBtnClickSliders(item.year)}}
                            href='/portfolio#section' >
                        {item.year}
                    </Button>
                </div>
            )
        })
    };

    render() {
        return(
            <div className='slid__slider'>
                <div>
                    <img className='slid__slider-films-first'
                         src='../img/border_slider/border_7.png' alt=''/>
                </div>
                <div>
                    <img className='slid__slider-films'
                         src='../img/border_slider/border_0.png' alt=''/>
                </div>
                { this.renderFilms() }
                <div>
                    <img className='slid__slider-films-end'
                         src='../img/border_slider/border_81.png' alt=''/>
                </div>
            </div>
        )
    }
}

export default SliderFilms;

import React from 'react';
import {Container, Col} from 'reactstrap';
import './btn_return.css';

const ButtonReturn = () => {
    return(
        <Container className="btn_return">
            <Col>
                <a className='btn btn_call_to' href='/'>
                    <img src="../img/btn_back.png" alt="back_to_home" style={{width: '8rem'}}/>
                </a>
            </Col>
        </Container>
    )
};

export default ButtonReturn;
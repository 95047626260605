import React from 'react';
import {
    Container,
    Row,
    Card,
    CardTitle,
    CardText,
    CardFooter,
    CardBody,
    CardHeader
} from 'reactstrap';
import './work_content.scss';
import Header from '../header/header';
import Footer from '../footer/footer';
import ButtonOrder from "../../moduls/button/btn_order";
import WorkMenu from "../../moduls/work_menu/work_menu";
import ButtonReturn from "../../moduls/button/btn_return/bnt_return";
import FadeTransitionWorkLayout from "../../../assets/transition/transition_works_layouts";

export class ApartmentLayout extends React.Component {

    renderText = () => {
        const textMoving= [
            {id: 1,
                works: 'погрузочно-разгрузочные работы',
                time: '08:00 - 20:00',
                order: '2',
                two: '',
                price: '500'
            },
            {id: 2,
                works: 'погрузочно-разгрузочные работы',
                time: '20:00 - 08:00',
                order: '2',
                two: 'Проезд грузчиков после 23:00 оплачивается отдельно',
                price: '600'
            },
            {id: 4,
                works: 'строительные материалы',
                time: '08:00 - 20:00',
                order: '2',
                two: '',
                price: '500'
            },
            {id: 5,
                works: 'строительные материалы',
                time: '20:00 - 08:00',
                order: '2',
                two: 'Проезд грузчиков после 23:00 оплачивается отдельно',
                price: '600'
            },
            {id: 6,
                works: 'вынос мусора',
                time: '08:00 - 20:00',
                order: '2',
                two: '',
                price: '500'
            },
            {id: 7,
                works: 'вынос мусора',
                time: '20:00 - 8:00',
                order: '2',
                two: '',
                price: '600'
            },
            {id: 8,
                works: 'подъем на этаж',
                time: '00:00 - 24:00',
                order: '0',
                two: '',
                price: '20'
            },
        ];

        return textMoving.map((item, i) => {
            return (
                <Card body className='work_content__card' key={i}>
                    <FadeTransitionWorkLayout>
                        <CardHeader className='work_content__card-info-header'>Тип работ:</CardHeader>
                        <CardHeader className='work_content__card-info-header-title'>{item.works}</CardHeader>
                        <CardBody>
                            <CardTitle>Время работы: {item.time}</CardTitle>
                            <CardText>Минимальный заказ: {item.order} часа</CardText>
                            <CardText>{item.two}</CardText>
                        </CardBody>
                        <CardFooter>Цена за одного грузчика:</CardFooter>
                        <CardFooter className='work_content__card-info-footer-price'>{item.price} рублей/час</CardFooter>
                    </FadeTransitionWorkLayout>
                </Card>
            )
        })
    };

    render() {
        return(
            <div>
                <Header/>
                <p><a name="section"></a></p>
                <ButtonReturn />
                <WorkMenu {...this.props}/>
                <Container className='work_content' >
                    <Row style={{marginTop: '5rem'}}>
                        <Card className='work_content__card'>
                            <CardHeader className='work_content__card-info-header-title'>
                                <FadeTransitionWorkLayout>
                                    <span>Квартирные и офисные перезды:</span>
                                </FadeTransitionWorkLayout>
                            </CardHeader>
                        </Card>
                    </Row>
                    <Row>
                        { this.renderText() }
                    </Row>
                </Container>
                <ButtonOrder/>
                <Footer/>
            </div>
        )
    }
}

export default ApartmentLayout;
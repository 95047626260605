import React from 'react';
import './work_content.scss';
import Header from '../header/header';
import Footer from '../footer/footer';
import ButtonOrder from "../../moduls/button/btn_order";
import ButtonReturn from "../../moduls/button/btn_return/bnt_return";
import WorkMenu from "../../moduls/work_menu/work_menu";

const OverLayout = (props) => {
    return (
        <div>
            <Header/>
            <p><a name="section"></a></p>
            <ButtonReturn />
            <WorkMenu {...props}/>
            <ButtonOrder />
            <Footer />
        </div>
    );
};

export default OverLayout;
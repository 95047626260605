import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import './header.scss';
import SvgVerticalLineHeader from '../../../assets/svg/svg_vertical_header';

const Header = () => {
    return (
        <div className='header header_bg'>
            <Container >
                <Row className='header_info'>
                    <Col xs="5 my-auto" className="header_social">
                        <Row >
                            <Col>
                                <a href="https://vk.com/trubach707">
                                    <img className='header_social-icon' src="../img/social_logo/logo_vk.png" alt="vkontakte" />
                                </a>
                                <a href="http://facebook.com">
                                    <img className='header_social-icon' src="../img/social_logo/logo_f.png" alt="facebook" />
                                </a>
                                <a href="http://instagram.com">
                                    <img className='header_social-icon' src="../img/social_logo/logo_i.png" alt="instagram"/>
                                </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                мы в соц сетях
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="5 my-auto" className='header_tel'>
                        <Row>
                            <Col>
                                <SvgVerticalLineHeader />
                            </Col>
                                <a href='tel:+79535858975' style={{textDecorationLine: 'none'}}>
                                    285-89–75
                                </a>
                            <Col>
                                <SvgVerticalLineHeader />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="2 my-auto" className='header_adr'>
                        <h6>КРАСНОЯРСК,</h6>
                        <h6>ул. Глинки, 37д,</h6>
                        <h6>офис 2–2</h6>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Header;

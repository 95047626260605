import React from 'react';
import './svg.scss';

const SvgHorisontalLineHome = () => {
    return (
        <svg className='svg_horisontal_line__home'>
            <rect
                width="10vh"
                height="0.2vh"
                style={{fill: '#633a21'}}/>
        </svg>
    )
};

export default SvgHorisontalLineHome;
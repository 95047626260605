import React from 'react';
import './svg.scss';

const SvgHorisontalLineWork = () => {
    return (
        <svg className='svg_horisontal_line__work'>
            <rect
                width="6vh"
                height="0.2vh"
                style={{fill: '#633a21'}}/>
        </svg>
    )
};

export default SvgHorisontalLineWork;
import React from 'react';
import {Card, CardImg, CardTitle, Col, Container, Row} from "reactstrap";
import SvgHorisontalLineWork from "../../../assets/svg/svg_horisontal_work";
import './work_menu.scss';
import FadeTransitionWorkMenu from "../../../assets/transition/transition_work_menu";

const WorkMenu = (props) => {
    const work = () => {
        const work_menu = [
            {id: 1,
                href: '/moving',
                alt: 'moving',
                img: '../img/menu_geo.jpg',
                p_one: 'услуги',
                p_two: 'грузчиков'
            },
            {id: 2,
                href: '/truckmoving',
                alt: 'truckmoving',
                img: '../img/menu_truck.jpg',
                p_one: 'грузоперевозки',
            },
            {id: 3,
                href: '/mebel',
                alt: 'sborka mebeli',
                img: "../img/menu_mebel.jpg",
                p_one: 'сборка разборка',
                p_two: 'мебели'
            },
            {id: 4,
                href: '/garbage',
                alt: 'garbage',
                img: "../img/menu_garbage.jpg",
                p_one: 'вынос и вывоз мусора',
            },
            {id: 5,
                href: '/apartment',
                alt: 'apartment moves',
                img: "../img/menu_moving.jpg",
                p_one: 'квартирные и офисные',
                p_two: 'переезды'
            },
            {id: 6,
                href: '/sklad',
                alt: 'work on sklad',
                img: '../img/menu_sklad.jpg',
                p_one: 'работа',
                p_two: 'на складах'
            },
            {id: 7,
                href: '/techniks',
                alt: 'technics',
                img: '../img/menu_kran.jpg',
                p_one: 'аренда',
                p_two: 'спецтехники'
            },
            {id: 8,
                href: '/over',
                alt: 'over',
                img: '../img/menu_over.jpg',
                p_one: 'и многое',
                p_two: 'другое'
            }];
        return work_menu.map((work, i) => {
            return (
                <Col className={work.href === props.match.url && 'work_menu_card-off'} key={i}>
                    <Card className="work_menu_card" >
                        <a href={work.href + '#section'} >
                            <CardImg top width="100%" src={work.img} alt={work.alt}/>
                        </a>
                        <CardTitle>
                            <SvgHorisontalLineWork/>
                            <p>{work.p_one}</p>
                            <p>{work.p_two}</p>
                            <SvgHorisontalLineWork/>
                        </CardTitle>
                    </Card>
                </Col>
            )
        })
    };
    return (
        <FadeTransitionWorkMenu>
            <Container className="work_menu">
                <Row>
                    { work() }
                </Row>
            </Container>
        </FadeTransitionWorkMenu>
    )
};

export default WorkMenu;
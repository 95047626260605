import React from 'react';
import './svg.scss';

const SvgVerticalLineHeader = () => {
    return (
        <svg className='svg_vertical_line__header'>
            <rect
                width="0.3vh"
                height="9vh"
                style={{fill: '#633a21'}}/>
        </svg>
    )
};

export default SvgVerticalLineHeader;
import React from 'react';
import FadeTransition from "../../../assets/transition/transition_home";
import {
    Container,
    Col,
    Row,
    Card,
    CardImg,
    CardTitle
}   from 'reactstrap';
import '../../../assets/blocks/main.scss';
import Header from '../header/header';
import Footer from '../footer/footer';
import SliderFilms from '../../moduls/slider/slider';
import SvgHorisontalLineHome from '../../../assets/svg/svg_horisontal_line';
import ButtonCall from "../../moduls/button/bnt_call";
import CarouselHome from "../../moduls/carousel/carousel";

const HomeLayout = (props) => {
    console.log('re')

    let state = props.portfolioPage;
    let getPhotosAction = props.getPhotosAction;

    return (
        <FadeTransition>
            <Header/>
            <Container>
                <Row>
                    <Col xs="11 "  className="home_info_payment">
                        <p>Работаем за наличный и безналичный расчет</p>
                        <p>с НДС и без</p>
                    </Col>
                    <Col xs="1" className="home_info_payment_img">
                        <img src="../img/nds.jpg" alt="truck" style={{width: '15vh'}}/>
                    </Col>
                </Row>
            </Container>

            <Container className="home_menu">
                <Row >
                    <Col lg="6">
                        <Row>
                            <Col>
                                <Card className="home_menu_card">
                                    <a href="/moving#section">
                                        <CardImg top width="100%" src="../img/menu_geo.jpg" alt="truckmoving" />
                                    </a>
                                    <CardTitle>
                                        <SvgHorisontalLineHome />
                                        <p>услуги</p>
                                        <p>грузчиков</p>
                                        <SvgHorisontalLineHome />
                                    </CardTitle>
                                </Card>

                            </Col>
                            <Col>
                                <Card className="home_menu_card">
                                    <a href="/truckmoving#section">
                                        <CardImg top width="100%" src="../img/menu_truck.jpg" alt="truckmoving" />
                                    </a>
                                    <CardTitle>
                                        <SvgHorisontalLineHome />
                                        <p>грузоперевозки</p>
                                        <SvgHorisontalLineHome />
                                    </CardTitle>
                                </Card>

                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row>
                            <Col>
                                <Card className="home_menu_card">
                                    <a href="/mebel#section">
                                        <CardImg top width="100%" src="../img/menu_mebel.jpg" alt="sborka mebeli" />
                                    </a>
                                    <CardTitle>
                                        <SvgHorisontalLineHome />
                                        <p>сборка разборка</p>
                                        <p>мебели</p>
                                        <SvgHorisontalLineHome />
                                    </CardTitle>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="home_menu_card">
                                    <a href="/garbage#section">
                                        <CardImg top width="100%" src="../img/menu_garbage.jpg" alt="garbage" />
                                    </a>
                                    <CardTitle>
                                        <SvgHorisontalLineHome />
                                        <p>вынос и вывоз мусора</p>
                                        <SvgHorisontalLineHome />
                                    </CardTitle>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container className="home_menu">
                <Row >
                    <Col lg="6">
                        <Row>
                            <Col>
                                <Card className="home_menu_card">
                                    <a href="/apartment#section">
                                        <CardImg top width="100%" src="../img/menu_moving.jpg" alt="apartment moves" />
                                    </a>
                                    <CardTitle>
                                            <SvgHorisontalLineHome />
                                            <p>квартирные и офисные</p>
                                            <p>переезды</p>
                                            <SvgHorisontalLineHome />
                                    </CardTitle>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="home_menu_card">
                                    <a href="/sklad#section">
                                        <CardImg top width="100%" src="../img/menu_sklad.jpg" alt="work on sklad" />
                                    </a>
                                    <CardTitle>
                                        <SvgHorisontalLineHome />
                                        <p>работа</p>
                                        <p>на складах</p>
                                        <SvgHorisontalLineHome />
                                    </CardTitle>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row>
                            <Col>
                                <Card className="home_menu_card">
                                    <a href="/techniks#section">
                                        <CardImg top width="100%" src="../img/menu_kran.jpg" alt="technics" />
                                    </a>
                                    <CardTitle>
                                        <SvgHorisontalLineHome />
                                        <p>аренда</p>
                                        <p>спецтехники</p>
                                        <SvgHorisontalLineHome />
                                    </CardTitle>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="home_menu_card">
                                    <a href="/over#section">
                                        <CardImg top width="100%" src="../img/menu_over.jpg" alt="over" />
                                    </a>
                                    <CardTitle>
                                        <SvgHorisontalLineHome />
                                        <p>и многое</p>
                                        <p>другое</p>
                                        <SvgHorisontalLineHome />
                                    </CardTitle>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ButtonCall />
            <Container className="home_we_worked" style={{height: '2rem'}}>
                <Row>
                    <Col xs="12" >
                        <p>
                            Мы работаем
                        </p>
                    </Col>
                </Row>
            </Container>
            <div style={{height: '20rem'}}>
                <SliderFilms year={state.year} getPhoto={getPhotosAction}/>
            </div>
            <CarouselHome />
            <Footer />
        </FadeTransition>
    );
};

export default HomeLayout;

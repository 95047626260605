import { connect } from "react-redux";
import { getPhotos } from "../../../store/reducers/portfolio/portf_action";
import HomeLayout from './home';

const mapStateToProps = state => {
    return {
        portfolioPage: state.portfolioPage,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPhotosAction: (year) => dispatch(getPhotos(year)),
    }
};

const HomeContainer = connect (mapStateToProps, mapDispatchToProps)(HomeLayout);
export default HomeContainer;
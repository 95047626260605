import fetchJsonp from "fetch-jsonp";

//вариант для встроенной VK.Api.call. работает криво практически во всех браузерах, но на всякий случай.

const VK = window.VK;
const photosVKApiCall = {
    getPhotos(offset, count) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            VK.Api.call(
                'photos.getAll',
                {owner_id: -100893666, extended: 1, count: count, offset: offset, v: '5.103', photo_sizes: 1},
                photos => resolve(photos)
            );
        });
    }
};

const photosVKApi = {
    getPhotos(count) {
        return new Promise((resolve, reject) => {
            fetchJsonp('https://api.vk.com/method/photos.get?' +
                'owner_id=-100893666&' +
                'album_id=219865514&' +
                'photo_sizes=1&' +
                'count=200&' +
                'rev=1' +
                'offset=0&access_token=e0cd3b47e0cd3b47e0cd3b4790e0a25a58ee0cde0cd3b47befc1417bef1740c41b711c8&v=5.103')
                .then(
                    photos => resolve(photos.json())
                )
                .catch(ex => {
                    console.log('parsing failed', ex)
                })
        })
    }

};

export default photosVKApi;
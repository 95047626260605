import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, CardImg, CardTitle, Col, Row} from "reactstrap";
import ButtonUp from "../button/btn_return/bnt_return";
import FadeTransitionPhoto from "../../../assets/transition/transition_photo";

const Photo = (props) => {

    let [photo, getPhoto] = useState(props.photo);
    let [i, addMorePhoto] = useState(7);
    const {isProcessing, error} = props;

    useEffect( () => {
        getPhoto(props.photo);
    }, [props.photo]);

    const photoArrayMini = photo.slice(0, i);

    const onBtnClick = year => {
        props.getPhoto(year);
    };

    const renderButtons = () => {
        const years = [
            {id: 1, year: 2020},
            {id: 2, year: 2019},
            {id: 3, year: 2018},
            {id: 4, year: 2017},
        ];
        return years.map((item, i) => {
            return (
                <Button
                    color='secondary'
                    key={i}
                    onClick={() => {
                        onBtnClick(item.year)}}>
                    {item.year}
                </Button>
            )
        })
    };

    const renderTemplate = () => {
        if (error) {
            return <p className="error">Во время загрузки фото произошла ошибка</p>
        }
        if (isProcessing) {
            return <p>Загрузка...</p>
        } else {
            return photoArrayMini.map((entry, i) => (
                <FadeTransitionPhoto>
                    <Card key={i}>
                        <CardImg src={entry.sizes[7].url} className='portfolio_cards'/>
                        <CardBody tag="h6">
                            <CardTitle>{entry.text}</CardTitle>
                        </CardBody>
                    </Card>
                </FadeTransitionPhoto>
            ))
        }
    };
    return (
        <div>
            <Row>
                <Col>
                 { renderButtons() }
                </Col>
            </Row>
            <h3 style={{marginTop: '1rem'}}>{ props.year} год</h3>
            <div className='portfolio_pre'>
                <Row style={{justifyContent: 'center'}}>
                        { renderTemplate() }
                </Row>
            </div>
            <div>
                { photoArrayMini.length === props.photo.length || isProcessing ? (
                    <a className='portfolio_pre_btn_not-active'
                       onClick={() => {addMorePhoto(i + 7)
                       }}>
                        <img src="../img/btn_show_more.png" alt="call_to" style={{width: '20rem'}}/>
                    </a>
                ) : (
                    <a
                       onClick={() => {addMorePhoto(i + 7)
                       }}>
                        <img src="../img/btn_show_more.png" alt="call_to" style={{width: '20rem'}}/>
                    </a>
                )}
            </div>
            <div>
                {photoArrayMini.length >= 7 ? (
                    <ButtonUp/>
                ) : (
                    <></>
                )}
            </div>
        </div>
    )
};

export default Photo;

Photo.propTypes = {
    getPhoto: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired
};
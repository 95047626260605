import React from 'react';
import {
    Container,
    Row,
    Card,
    CardTitle,
    CardText,
    CardFooter,
    CardBody,
    CardHeader
} from 'reactstrap';
import './work_content.scss';
import Header from '../header/header';
import Footer from '../footer/footer';
import ButtonOrder from "../../moduls/button/btn_order";
import WorkMenu from "../../moduls/work_menu/work_menu";
import ButtonReturn from "../../moduls/button/btn_return/bnt_return";
import FadeTransitionWorkLayout from "../../../assets/transition/transition_works_layouts";

export class GarbageLayout extends React.Component {

    renderTextMoving = () => {
        const textMoving= [
            {id: 6,
                works: 'Вынос мусора',
                time: '08:00 - 20:00',
                order: '2',
                two: '',
                price: '500 рублей/час'
            },
            {id: 7,
                works: 'Вынос мусора',
                time: '20:00 - 8:00',
                order: '2',
                two: 'Проезд грузчиков после 23:00 оплачивается отдельно',
                price: '800 рублей/час'
            },
            {id: 8,
                works: 'Вынос мусора поэтажно',
                time: '00:00 - 24:00',
                order: '0',
                two: '',
                price: '25 рублей/этаж'
            },
        ];

        return textMoving.map((item, i) => {
            return (
                <Card body className='work_content__card' key={i}>
                    <FadeTransitionWorkLayout>
                        <CardHeader className='work_content__card-info-header'>Тип работ:</CardHeader>
                        <CardHeader className='work_content__card-info-header-title'>{item.works}</CardHeader>

                        <CardBody>
                            <CardTitle key='time'>Время работы: {item.time}</CardTitle>
                            <CardText>Минимальный заказ: {item.order} часа</CardText>
                            <CardText>{item.two}</CardText>
                        </CardBody>
                        <CardFooter>Цена за одного грузчика:</CardFooter>
                        <CardFooter className='work_content__card-info-footer-price'>{item.price}</CardFooter>
                    </FadeTransitionWorkLayout>
                </Card>
            )
        })
    };

    renderTextAuto = () => {
        const text= [
            {id: 1,
                works: 'до 1500 кг',
                time: '08:00 - 20:00',
                price: '5000'
            },
            {id: 4,
                works: 'до 3000 кг',
                time: '08:00 - 20:00',
                price: '8000'
            },
            {id: 6,
                works: 'до 5000 кг',
                time: '08:00 - 20:00',
                price: '12000'
            },
            {id: 7,
                works: 'до 10000 кг',
                time: '20:00 - 08:00',
                price: '14000'
            }
        ];

        return text.map((item, i) => {
            return (
                <Card body className='work_content__card' key={i}>
                    <CardHeader className='work_content__card-info-header'>Грузоподъемность автомобиля:</CardHeader>
                    <CardHeader className='work_content__card-info-header-title'>{item.works}</CardHeader>

                    <CardBody>
                        <CardTitle>Время работы: {item.time}</CardTitle>
                    </CardBody>
                    <CardFooter>Цена:</CardFooter>
                    <CardFooter className='work_content__card-info-footer-price'>{item.price} рублей за одну поездку</CardFooter>
                </Card>
            )
        })
    };

    render() {
        return(
            <div>
                <Header/>
                <p><a name="section"></a></p>
                <ButtonReturn />
                <WorkMenu {...this.props}/>
                <Container className='work_content'>
                    <Row>
                        <Card className='work_content__card'>
                            <CardHeader className='work_content__card-info-header-title'>
                                <FadeTransitionWorkLayout>
                                    <span>Вынос и вывоз мусора:</span>
                                </FadeTransitionWorkLayout>
                            </CardHeader>
                        </Card>
                    </Row>
                    <Row>
                        { this.renderTextMoving() }
                    </Row>
                    <Row>
                        { this.renderTextAuto() }
                    </Row>
                </Container>
                <ButtonOrder/>
                <Footer/>
            </div>
        )
    }
}

export default GarbageLayout;
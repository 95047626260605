import React from 'react';
import {CSSTransitionGroup} from "react-transition-group";
import './transition.scss';

const FadeTransitionWorkMenu = (props) => (
    <CSSTransitionGroup
        {...props}
        transitionName="work_menu"
        transitionAppear={true}
        transitionAppearTimeout={2400}
        transitionEnter={true}
        transitionLeave={true}
    />
);
export default FadeTransitionWorkMenu;
import React, { useState, useMemo, useCallback } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    Button
} from 'reactstrap';
import './carousel.scss';

const items = [
    {
        src: '../img/border_slider/2020.jpg',
        border: '../img/border_slider/border_1.png',
        year: 2020
    },
    {
        src: '../img/border_slider/2019.jpg',
        border: '../img/border_slider/border_2.png',
        year: 2019
    },
    {
        src: '../img/border_slider/2018.jpg',
        border: '../img/border_slider/border_3.png',
        year: 2018
    },
    {
        src: '../img/border_slider/2017.jpg',
        border: '../img/border_slider/border_4.png',
        year: 2017
    },
    {
        src: '../img/border_slider/2016.jpg',
        border: '../img/border_slider/border_5.png',
        year: 2016
    }
];

const CarouselHome = (props) => {

    const onBtnClickSliders = year => {
        localStorage.setItem('year', year);
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = useCallback(
        () => {
            if (animating) return;
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            setActiveIndex(nextIndex);
        },
        [activeIndex, animating, items]
    );

    const previous = useCallback(
        () => {
            if (animating) return;
            const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            setActiveIndex(nextIndex);
        },
        [activeIndex, animating, items]
    );

    const goToIndex = useCallback(
        (newIndex) => {
            if (animating) return;
            setActiveIndex(newIndex);
        },
        [animating]
    );

    const slides = useMemo(() => {
        return items.map((item, i) => {
            return (
                <CarouselItem className='carousel__home_slide'
                              onExiting={() => setAnimating(true)}
                              onExited={() => setAnimating(false)}
                              key={i}
                >
                    <div>
                        <a href='/portfolio'
                           onClick={() => {
                               onBtnClickSliders(item.year)
                           }}>
                            <img className='carousel__home_slide_films' src={item.src} alt={item.year}/>
                            <img className='carousel__home_slide_border' src={item.border} alt={item.year}/>
                        </a>
                        <Button className='carousel__home_slide_btn'
                                key={item}
                                onClick={() => {
                                    onBtnClickSliders(item.year)
                                }}
                                href='/portfolio'>
                            {item.year}</Button>
                    </div>
                </CarouselItem>
            );
        });
    }, [items]);

    return (
        <Carousel
            className='carousel__home'
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl className='carousel__home_slide_control_left' direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl className='carousel__home_slide_control_right' direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
};

export default CarouselHome;
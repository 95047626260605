import React from 'react';
import {CSSTransitionGroup} from "react-transition-group";
import './transition.scss';

const FadeTransition = (props) => (
    <CSSTransitionGroup
        {...props}
        transitionName="home"
        transitionAppear={true}
        transitionAppearTimeout={650}
        transitionEnter={false}
        transitionLeave={false}
    />
);

export default FadeTransition;
import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Switch, Route } from "react-router";
import './assets/blocks/main.scss';
import './assets/fonts/fonts.scss';

import HomeContainer from './components/layouts/home/homeContainer.jsx';
import MovingLayout from "./components/layouts/work_contents/moving";
import TruckLayout from "./components/layouts/work_contents/truckmoving";
import PortfolioContainer from "./components/layouts/work_contents/portfolio/portfolioContainer";
import MebelLayout from "./components/layouts/work_contents/mebel";
import GarbageLayout from "./components/layouts/work_contents/garbage";
import ApartmentLayout from "./components/layouts/work_contents/apartment";
import SkladLayout from "./components/layouts/work_contents/work_storeroom";
import TechniksLayout from "./components/layouts/work_contents/techniks";
import OverLayout from "./components/layouts/work_contents/over";

const PageNotFound = ({location}) => (
    <div className="text-center mt-5">
        <h3>Oops! That page <code>{location.pathname}</code> can’t be found.</h3>
        <p>It looks like nothing was found at this Page or URL.</p>
    </div>
);

class App extends Component {

    render() {
        return (
            <BrowserRouter>
                <div>
                    <Switch>
                        <Route exact path="/" render={ (props) => <HomeContainer {...props} /> } />
                        <Route exact path="/home" render={ (props) => <HomeContainer {...props} /> } />
                        <Route exact path="/portfolio"  render={ (props) => <PortfolioContainer {...props} /> } />
                        <Route exact path="/moving"  render={ (props) => <MovingLayout {...props} /> } />
                        <Route exact path="/truckmoving"  render={ (props) => <TruckLayout {...props} /> } />
                        <Route exact path="/mebel"  render={ (props) => <MebelLayout {...props} /> } />
                        <Route exact path="/garbage"  render={ (props) => <GarbageLayout {...props} /> } />
                        <Route exact path="/apartment"  render={ (props) => <ApartmentLayout {...props} /> } />
                        <Route exact path="/sklad"  render={ (props) => <SkladLayout {...props} /> } />
                        <Route exact path="/techniks"  render={ (props) => <TechniksLayout {...props} /> } />
                        <Route exact path="/over"  render={OverLayout} />
                        <Route exact render={(props) => <PageNotFound {...props} />} />
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default (App)

import React from 'react';
import {Container, Row, Col} from 'reactstrap';

const ButtonOrder = () => {

    return (
        <div>
            <Container>
                <Row>
                    <Col sm="12" style={{
                        textAlign: 'center',
                        marginTop: '10vh',
                        marginBottom: '10vh'
                    }}>
                        <a className='btn btn_call_to' href='tel:+79535858975'>
                            <img src="../img/btn_order.png" alt="call_to" style={{width: '20rem'}}/>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default ButtonOrder;
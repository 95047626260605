import React from 'react';
import {CSSTransitionGroup} from "react-transition-group";
import './transition.scss';

const FadeTransitionPhoto = (props) => (
    <CSSTransitionGroup
        {...props}
        transitionName="photo"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={true}
        transitionLeave={true}
    />
);
export default FadeTransitionPhoto;
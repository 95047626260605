import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Portfolio from './portfolio';
import {getAllPhoto, getPhotos, setYears} from "../../../../store/reducers/portfolio/portf_action";

class PortfolioContainer extends React.Component {

    componentDidMount() {
        if (this.props.portfolioPage.year === null) {
            this.props.setYearsAction();

            const loop = () => {
                if (window.VK_init) {
                    this._timer = null;
                    this.props.getAllPhotosAction();
                    return;
                }
                this._timer = setTimeout(loop, 20);
            };

            loop();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.portfolioPage.year !== prevProps.portfolioPage.year) {
        }
    }

    componentWillUnmount() {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }

    render () {
        return (
            <Portfolio {...this.props} />
        )
    }
}

const mapStateToProps = state => {
    return {
        portfolioPage: state.portfolioPage,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPhotosAction: (year) => dispatch(getPhotos(year)),
        setYearsAction: (year) => dispatch(setYears(year)),
        getAllPhotosAction: (year) => dispatch(getAllPhoto(year))
    }
};

export default connect (mapStateToProps, mapDispatchToProps)(PortfolioContainer);